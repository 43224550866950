//display date in this format : Jeudi 25 Janvier 2024
module.exports.dateLong = (date) => {
  const utcDate = new Date(date);

  // Construction manuelle de la date en UTC
  const day = utcDate.getUTCDate();
  const month = utcDate.getUTCMonth();
  const year = utcDate.getUTCFullYear();
  const weekday = utcDate.getUTCDay();

  const weekdays = [
    "dimanche",
    "lundi",
    "mardi",
    "mercredi",
    "jeudi",
    "vendredi",
    "samedi",
  ];
  const months = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  return `${weekdays[weekday]} ${day} ${months[month]} ${year}`;
};

//display date in this format : Jeudi 25 Janvier
module.exports.dateShortText = (date) => {
  const options = {
    weekday: "long",
    day: "2-digit",
    month: "long",
  };

  const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

//display date in this format : 2024-02-29 09:00
module.exports.dateShortHours = (date) => {
  const formattedDate = new Date(date);

  const year = formattedDate.getFullYear();
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = formattedDate.getDate().toString().padStart(2, "0");
  const hours = formattedDate.getHours().toString().padStart(2, "0");
  const minutes = formattedDate.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

//check date validity
module.exports.dateStringIsValid = (dateString) => {
  let dateStr = dateString;

  //detect if birthDate has this regex /^..-..-....$/ or /^..\/..\/....$/ (xx-xx-xxxx or xx/xx/xxxx)
  if (dateStr.match(/^..-..-....$/) || dateStr.match(/^..\/..\/....$/)) {
    //detect if - or / in date
    if (dateStr.includes("-")) {
      const dateParts = dateStr.split("-");
      dateStr = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
    } else {
      const dateParts = dateStr.split("/");
      dateStr = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
    }
  }
  const date = new Date(dateStr);
  return date instanceof Date && !isNaN(date);
};

module.exports.formatTimeDifference = (timeDifferenceDate) => {
  const years = timeDifferenceDate.getUTCFullYear() - 1970;
  const months = timeDifferenceDate.getUTCMonth();
  const days = timeDifferenceDate.getUTCDate() - 1; // Moins 1 car getUTCDate renvoie le jour du mois (1-31)
  const hours = timeDifferenceDate.getUTCHours();
  const minutes = timeDifferenceDate.getUTCMinutes();
  const seconds = timeDifferenceDate.getUTCSeconds();

  if (years > 0) {
    return `${years} an${years > 1 ? "s" : ""}`;
  } else if (months > 0) {
    return `${months} mois`;
  } else if (days > 0) {
    return `${days} jour${days > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    return `${hours} heure${hours > 1 ? "s" : ""}`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""}`;
  } else {
    return `${seconds} seconde${seconds > 1 ? "s" : ""}`;
  }
};

module.exports.dateToEpoch = (date) => {
  let utcDate = new Date(date);
  utcDate.setUTCHours(0, 0, 0, 0);
  return utcDate;
};

module.exports.dateWithoutTime = (date) => {
  //if real date
  if (date instanceof Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  } else {
    //if epoch
    const dateF = new Date(date);
    return new Date(dateF.getFullYear(), dateF.getMonth(), dateF.getDate());
  }
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CheckIcon,
  CogIcon,
  CrossIcon,
  Download,
  OfflineIcon,
  Synchronisation,
  WorkfullyLogo,
} from "../../assets/icons";
import UseContext from "../../hooks/useContext";
import ApiDatabase from "../../server";
import { dateShortHours, dateShortText } from "../../utils/Date";
import { getAllRequests, removeRequest } from "../../utils/indexedDB";
import versionChecker from "../../utils/versionChecker";

const PageContainerSiteBadge = ({ isPageFull, children }) => {
  const {
    allMissionToken,
    setAllMissionToken,
    isOnline,
    setIsOnline,
    setMissions,
    setTimeCut,
    setAdminPinCode,
  } = UseContext();
  const navigate = useNavigate();
  const [date, setDate] = useState({
    dateText: "",
    hoursText: "",
    dateTextHours: "",
  });
  const [isSync, setIsSync] = useState(false);
  const [stepSync, setStepSync] = useState("");
  const [versionInfo, setVersionInfo] = useState({
    versionLocal: null,
    versionServer: null,
    needsUpdate: false,
  });
  const companyName = localStorage.getItem("company") || "Unknown Company";
  const badgerName = localStorage.getItem("badgerName");
  const companySelected = localStorage.getItem("companyId");

  const dateManage = () => {
    const dateNow = new Date();
    const dateShort = dateShortText(dateNow);
    const dateHours = `${dateNow.getHours() < 10 ? "0" : ""}${dateNow.getHours()}:${dateNow.getMinutes() < 10 ? "0" : ""}${dateNow.getMinutes()}`;
    const dateTextHours = dateShortHours(dateNow);
    setDate({
      dateText: dateShort,
      hoursText: dateHours,
      dateTextHours: dateTextHours,
    });
  };

  useEffect(() => {
    dateManage();
    const intervalId = setInterval(() => {
      dateManage();
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchVersionInfo = async () => {
      const versionData = await versionChecker();
      setVersionInfo(versionData);
    };

    fetchVersionInfo();

    const intervalId = setInterval(fetchVersionInfo, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const navigateParameters = () => {
    navigate("/app/parameters");
  };

  useEffect(() => {
    const handleNetworkChange = async () => {
      setIsOnline(await ApiDatabase.checkConnection());
    };

    handleNetworkChange();

    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    const intervalId = setInterval(handleNetworkChange, 300000);

    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
      clearInterval(intervalId);
    };
  }, [setIsOnline]);

  const handleSync = async () => {
    setIsSync(true);
    setStepSync("Vérification de la connexion...");

    if (await ApiDatabase.checkConnection()) {
      await synchronizeRequests();

      setStepSync("Récupération des codes pin du jour.");
      if (date.dateTextHours) {
        try {
          await ApiDatabase.getAdminPinCode(
            {},
            async (data) => {
              setAdminPinCode(data.adminPinCode);
              setStepSync("Récupération des codes de mission.");
              await ApiDatabase.getTokenMissions(
                { date: date.dateTextHours },
                async (data) => {
                  const allMissionToken = data.map((mission) => ({
                    _id: mission._id,
                    firstname: mission.firstname,
                    lastname: mission.lastname,
                    tokenMission: mission.tokenMission,
                    timeSheet: {
                      [date.dateTextHours.split(" ")[0]]: mission.timeSheet,
                    },
                    timeCut: mission.timeCut,
                  }));
                  setAllMissionToken(allMissionToken);
                  if (companySelected) {
                    setStepSync("Récupération des missions du jour.");
                    await ApiDatabase.getMissions(
                      {
                        company: companySelected,
                        date:
                          new Date().toISOString().split("T")[0] +
                          "T00:00:00.000Z",
                      },
                      async (data) => {
                        setMissions(data.missions || []);
                        setTimeCut(data.timeCut);

                        setStepSync("Synchronisation effectuée.");
                        setIsOnline(true);
                        await new Promise((resolve) =>
                          setTimeout(() => {
                            setIsSync(false);
                            resolve();
                          }, 3000)
                        );
                      },
                      (err) => {
                        console.log("err", err);
                      }
                    );
                  }
                }
              );
            },
            (err) => {
              console.log("err", err);
            }
          );
        } catch (err) {
          await handleError("Synchronisation échouée, veuillez réessayer.");
        }
      }
    } else {
      await handleError("Synchronisation échouée, veuillez réessayer.");
    }
  };

  const synchronizeRequests = async () => {
    const company = localStorage.getItem("company");
    const queue = await getAllRequests();
    const filteredQueue = queue.filter(
      (request) => request.company === company
    );

    setStepSync(
      `Synchronisation des pointages enregistrés : 0/${filteredQueue.length}`
    );

    try {
      for (let i = 0; i < filteredQueue.length; i++) {
        const request = filteredQueue[i];
        await processRequest(request, i, filteredQueue.length);
      }
      setStepSync("Synchronisation des pointages effectuée.");
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve();
        }, 2000)
      );
    } catch (globalError) {
      console.error("Erreur globale lors de la synchronisation", globalError);
      await handleError("Synchronisation échouée, veuillez réessayer.");
    }
  };

  const processRequest = async (request, index, total) => {
    try {
      await ApiDatabase.makeRequest(
        request.url,
        request.method,
        { ...request.data, useSpecialTimeout: true, skipEnqueueRequest: true },
        async (data) => {
          setStepSync(
            `Synchronisation des pointages enregistrés : ${index + 1}/${total}`
          );
          await removeRequest(request.id);
          return true;
        },
        async (error) => {
          await handleError("Synchronisation échouée, veuillez réessayer.");
          if (shouldStopSync(error)) {
            console.log("Erreur lors de la synchronisation", error);
          }
        }
      );
    } catch (error) {
      await handleError("Synchronisation échouée, veuillez réessayer.");
      if (shouldStopSync(error)) {
        console.log("Erreur lors de la synchronisation", error);
      }
    }
  };

  const shouldStopSync = (error) => {
    return (
      error.code === "ECONNABORTED" ||
      error.code === "ERR_CONNECTION_TIMED_OUT" ||
      error.code === "ERR_TIMED_OUT" ||
      error.code === "ERR_CONNECTION_RESET" ||
      (error.response && error.response.status === 500) ||
      error.message === "No internet connection - request queued" ||
      error.message ===
        "Request timed out - it has been queued for later execution" ||
      error.message === "No internet access or request timed out" ||
      error.message === "Network Error" ||
      error.message === "Failed to fetch" ||
      error.message === "Network request failed" ||
      error.message === "Connection refused" ||
      error.message === "Request aborted" ||
      error.message === "Internet Disconnected" ||
      error.message === "Unable to resolve the server's DNS address"
    );
  };

  const handleError = async (message) => {
    setStepSync(message);
    await new Promise((resolve) =>
      setTimeout(() => {
        setIsSync(false);
        resolve();
      }, 3000)
    );
  };

  const downloadMaj = () => {
    caches.keys().then((names) => {
      for (let name of names) {
        caches.delete(name);
      }
    });

    window.location.href =
      window.location.origin +
      window.location.pathname +
      "?cache-bust=" +
      new Date().getTime();
  };

  return (
    <div className="flex flex-col h-screen select-none">
      <div className={"flex flex-col flex-1 max-h-full"}>
        <div className={"absolute top-0 left-0 p-1 text-sm text-gray-500"}>
          {versionInfo.versionLocal ? `v${versionInfo.versionLocal}` : ""}
        </div>
        {isSync && (
          <div
            className={`${stepSync === "Synchronisation échouée, veuillez réessayer." ? "bg-red-100 text-red-900" : stepSync === "Synchronisation effectuée." ? "bg-green-100 text-green-900" : "bg-gray-100 text-grey-900"} flex items-center p-1`}
          >
            <div className={"flex gap-1 mx-auto"}>
              {stepSync === "Synchronisation échouée, veuillez réessayer." && (
                <CrossIcon wh={20} color={"#7F1D1D"} />
              )}
              {stepSync === "Synchronisation effectuée." && (
                <CheckIcon wh={20} color={"#064E3B"} />
              )}
              {stepSync !== "Synchronisation échouée, veuillez réessayer." &&
                stepSync !== "Synchronisation effectuée." && (
                  <div className={"flex items-center animate-spinReverse"}>
                    <Synchronisation wh={20} color={"#111827"} />
                  </div>
                )}
              {stepSync}
            </div>
          </div>
        )}
        <div className={"flex h-full bg-purple-50 p-2 overflow-auto"}>
          <div className={"flex w-full"}>
            <div
              className={
                "w-full flex flex-col items-center justify-between p-[60px] info-badger"
              }
            >
              <div className={"flex justify-between w-full info-badger-header"}>
                <div className={"flex flex-col"}>
                  <div className={"text-gray-700 text-lg"}>{companyName}</div>
                  <div className={"text-lg font-bold leading-4"}>
                    {badgerName}
                  </div>
                </div>

                <div className={"flex gap-3"}>
                  {!isOnline &&
                    allMissionToken &&
                    !isSync &&
                    Object.keys(allMissionToken).length > 0 && (
                      <div
                        className={
                          "w-9 h-9 rounded-lg flex justify-center items-center cursor-pointer info-badger-header-offline"
                        }
                      >
                        <OfflineIcon wh={32} color={"#374151"} />
                      </div>
                    )}
                  {!isOnline &&
                    allMissionToken &&
                    !isSync &&
                    Object.keys(allMissionToken).length === 0 && (
                      <div
                        className={
                          "w-9 h-9 rounded-lg flex justify-center items-center cursor-pointer info-badger-header-offline"
                        }
                      >
                        <OfflineIcon wh={32} color={"#374151"} />
                      </div>
                    )}
                  {versionInfo.needsUpdate && isOnline && !isSync && (
                    <div
                      className={
                        "w-9 h-9 rounded-lg flex justify-center items-center cursor-pointer"
                      }
                      onClick={() => downloadMaj()}
                    >
                      <Download wh={32} color={"#374151"} />
                    </div>
                  )}
                  <div
                    className={`w-9 h-9 rounded-lg flex justify-center items-center cursor-pointer ${isSync ? "animate-spinReverse" : ""}`}
                    onClick={handleSync}
                  >
                    <Synchronisation wh={32} color={"#374151"} />
                  </div>
                  <div
                    className={
                      "w-9 h-9 rounded-lg flex justify-center items-center cursor-pointer"
                    }
                    onClick={navigateParameters}
                  >
                    <CogIcon wh={32} color={"#374151"} />
                  </div>
                </div>
              </div>
              <div className={"flex flex-col text-center info-badger-time"}>
                <div className={"capitalize text-gray-700 text-3xl"}>
                  {date.dateText}
                </div>
                <div className={"font-[Montserrat] font-bold text-9xl"}>
                  {date.hoursText}
                </div>
              </div>
              <WorkfullyLogo w={169} h={37} />
            </div>
            <div className={"w-full touchpad-badger"}>
              <div
                className={`h-full border rounded-xl bg-white p-10 flex flex-col justify-center gap-6 ${isPageFull ? "h-full" : "max-h-full"}`}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContainerSiteBadge;

import axios from "axios";
import { jwtDecode } from "jwt-decode";
import io from "socket.io-client";
import {
  addRequest,
  getAllLogErrors,
  getAllLogs,
  getAllRequests,
  removeLog,
  removeLogError,
  removeRequest,
} from "../utils/indexedDB";
import { reorderPeriods } from "../utils/Periods";
import {
  addResponseToCache,
  getResponseFromCache,
} from "../utils/responseCache";
import { addMinute, calculateTotalHoursAndPause } from "../utils/Time";

let currentSocket = null;
let currentRoom = {};
let ipInfoUser = {};
let isCheckingConnection = false;

const getUserInfo = async () => {
  try {
    if (Object.keys(ipInfoUser).length > 0) return ipInfoUser;

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Request timed out")), 1000)
    );

    const ipInfoPromise = axios.get(
      `https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`
    );

    const response = await Promise.race([timeoutPromise, ipInfoPromise]);

    ipInfoUser = response.data;

    return response.data;
  } catch (error) {
    console.error("Error fetching user info:", error);
    return {};
  }
};

const storeToken = (token) => {
  localStorage.setItem("token", token);
};

const removeToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("companyId");
  localStorage.removeItem("badgerId");
  localStorage.removeItem("companyName");
  localStorage.removeItem("badgerName");
};

const setAuthHeader = () => {
  const token = localStorage.getItem("token");
  const companyId = localStorage.getItem("companyId");
  const badgerId = localStorage.getItem("badgerId");

  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
  if (companyId) {
    axios.defaults.headers.common["X-Company-Id"] = companyId;
  } else {
    delete axios.defaults.headers.common["X-Company-Id"];
  }
  if (badgerId) {
    axios.defaults.headers.common["X-Badger-Id"] = badgerId;
  } else {
    delete axios.defaults.headers.common["X-Badger-Id"];
  }
};

const renewToken = async () => {
  try {
    const userInfo = await getUserInfo();

    setAuthHeader();

    const batteryLevel = updateBatteryLevel();
    const headers = {
      "X-User-Info": JSON.stringify(userInfo),
    };

    if (batteryLevel) {
      headers["X-Battery-Level"] = batteryLevel;
    }

    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}user/badger/renewToken`,
      withCredentials: true,
      headers,
    });

    if (response.data.errors) {
      console.error("Error renewing token:", response.data.errors);
    } else {
      if (response.headers.authorization) {
        const token = response.headers.authorization.split(" ")[1];
        storeToken(token);

        return token;
      } else {
        console.error("Error renewing token: no token in response");
      }
    }
  } catch (error) {
    console.error("Error renewing token:", error);
  }
};

const isTokenExpired = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return true;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (!decodedToken.exp) return false;

    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
};

const renewTokenIfNeeded = async () => {
  if (!isTokenExpired()) {
    return;
  }

  try {
    await renewToken();
  } catch (error) {
    console.error("Error renewing token:", error);
  }
};

const updateBatteryLevel = () => {
  const batteryLevel = localStorage.getItem("battery");
  if (batteryLevel) {
    return batteryLevel;
  }
  return null;
};

const getStorage = async () => {
  if ("storage" in navigator && "estimate" in navigator.storage) {
    try {
      const { usage } = await navigator.storage.estimate();
      return usage;
    } catch (error) {
      console.error("Erreur lors de l'obtention du stockage : ", error);
    }
  } else {
    console.warn(
      "L'API de stockage n'est pas prise en charge dans votre navigateur.",
    );
  }
};

const makeRequest = async (url, method, data, resolve, reject) => {
  const cacheKey = `${method}_${url}_${JSON.stringify(data)}`;
  console.log("cacheKey", cacheKey);

  try {
    const cachedResponse = await getResponseFromCache({ method, url, data });

    if (cachedResponse) {
      resolve(cachedResponse);
      return;
    }

    const shouldQueueRequest =
      !(await checkConnection()) &&
      (cacheKey.startsWith("post_timeSheet/addHour") ||
        cacheKey.startsWith("post_mission/updateTempWorkerPhone"));

    if (shouldQueueRequest) {
      await enqueueRequest({ url, method, data });
      reject(new Error("No internet connection - request queued"));
      return;
    }

    if (!cacheKey.startsWith("post_auth/badger/login")) {
      await renewTokenIfNeeded();
    }

    const userInfo = await getUserInfo();
    const batteryLevel = updateBatteryLevel();
    const storage = await getStorage();
    setAuthHeader();

    const headers = {
      "X-User-Info": JSON.stringify(userInfo),
    };

    if (batteryLevel) {
      headers["X-Battery-Level"] = batteryLevel;
    }

    if (storage) {
      headers["X-Storage"] = storage;
    }

    const fetchPromise = axios({
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
      withCredentials: true,
      headers,
      data,
    });

    let promises = [fetchPromise];

    if (
      cacheKey.startsWith("post_timeSheet/addHour") ||
      cacheKey.startsWith("post_mission/updateTempWorkerPhone") ||
      cacheKey.startsWith("get_auth/badger/loginComplete")
    ) {
      const timeoutDuration = data.useSpecialTimeout ? 3000 : 1000;
      const timeoutPromise = new Promise((_, timeoutReject) => {
        setTimeout(() => {
          timeoutReject(
            new Error(
              `Request timed out - it has been queued for later execution`
            )
          );
        }, timeoutDuration);
      });

      promises.push(timeoutPromise);
    }

    const response = await Promise.race(promises);

    if (response.data.errors) {
      reject(response.data.errors);
    } else {
      if (response.headers.authorization) {
        const token = response.headers.authorization.split(" ")[1];
        storeToken(token);
      }

      // Ajoutez la réponse dans le cache
      if (
        !cacheKey.startsWith("post_timeSheet/addHour") &&
        !cacheKey.startsWith("post_auth/badger/login") &&
        !cacheKey.startsWith("get_bigCompany/employee/companies") &&
        !cacheKey.startsWith("get_mission/tokenMissions") &&
        !cacheKey.startsWith("get_mission/list") &&
        !cacheKey.startsWith("get_version/latest") &&
        !cacheKey.startsWith("post_mission/updateTempWorkerPhone") &&
        !cacheKey.startsWith("post_mission/updateTempWorkerToken") &&
        !cacheKey.startsWith("post_logAddHourBadger/add")
      ) {
        await addResponseToCache(
          { method: "GET", url: cacheKey },
          response.data
        );
      }

      //tokenMissions strategy
      if (cacheKey.startsWith("get_mission/tokenMissions") && response.data) {
        const tokenMission = response.data;
        const allRequests = await getAllRequests();

        for (let i = 0; i < allRequests.length; i++) {
          const request = allRequests[i];
          for (let j = 0; j < tokenMission.length; j++) {
            let timeSheet = tokenMission[j].timeSheet;
            if (
              request.data?.tokenMission === tokenMission[j].tokenMission &&
              request.data.date
            ) {
              if (
                timeSheet.some(
                  (entry) =>
                    entry.hours.idStart === request.data.idHour ||
                    entry.hours.idEnd === request.data.idHour
                )
              ) {
                continue;
              }

              let timePart = request.data.date.split(" ")[1];
              if (
                !/^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}$/.test(
                  request.data.date
                )
              ) {
                return null;
              }

              if (timeSheet.length === 0) {
                timeSheet.push({
                  hours: {
                    start: timePart,
                    end: "",
                    idStart: request.data.idHour,
                    idEnd: "",
                  },
                  position: 1,
                });

                response.data[j].timeSheet = timeSheet;

                continue;
              }

              const lastEntryIndex = timeSheet.length - 1;
              const lastEntry = timeSheet[lastEntryIndex];

              if (
                lastEntry.hours.end === timePart ||
                lastEntry.hours.start === timePart
              ) {
                timePart = addMinute(timePart);
              }

              if (lastEntry.hours.end) {
                timeSheet.push({
                  hours: {
                    start: timePart,
                    end: "",
                    idStart: request.data.idHour,
                    idEnd: "",
                  },
                  position: lastEntryIndex + 2,
                });
              } else {
                timeSheet[lastEntryIndex] = {
                  ...lastEntry,
                  hours: {
                    ...lastEntry.hours,
                    end: timePart,
                    idEnd: request.data.idHour,
                  },
                };
              }
              timeSheet = reorderPeriods(timeSheet);
              response.data[j].timeSheet = timeSheet;
            }
          }
        }
      }

      if (cacheKey.startsWith("get_mission/list") && response.data) {
        const missionList = response.data;
        const allRequests = await getAllRequests();

        for (let i = 0; i < allRequests.length; i++) {
          const request = allRequests[i];
          for (let j = 0; j < missionList?.missions.length; j++) {
            let mission = missionList.missions[j];
            if (
              mission.user?.tokenMission === request.data?.tokenMission &&
              request.data?.date
            ) {
              let timePart = request.data.date.split(" ")[1];
              if (
                !/^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}$/.test(
                  request.data.date
                )
              ) {
                return null;
              }

              const timeSheets = mission.timeSheets || [];

              if (
                timeSheets.some((sheet) =>
                  sheet.periods.some(
                    (period) =>
                      period.hours.idStart === request.data.idHour ||
                      period.hours.idEnd === request.data.idHour
                  )
                )
              ) {
                continue;
              }

              let currentSheet = timeSheets[0];
              if (!currentSheet) {
                currentSheet = {
                  date: mission.date,
                  idMission: mission.idMission,
                  pause: 0,
                  totalHours: 0,
                  periods: [],
                };
                timeSheets.push(currentSheet);
              }

              const periods = currentSheet.periods || [];
              const lastEntryIndex = periods.length - 1;
              const lastEntry = periods[lastEntryIndex];

              if (lastEntry && lastEntry.hours.end) {
                const newPeriod = {
                  hours: {
                    start: timePart,
                    end: "",
                    idStart: request.data.idHour,
                    idEnd: "",
                  },
                  position: lastEntryIndex + 2,
                };
                currentSheet.periods.push(newPeriod);
              } else if (lastEntry) {
                lastEntry.hours.end = timePart;
                lastEntry.hours.idEnd = request.data.idHour;
              } else {
                const newPeriod = {
                  hours: {
                    start: timePart,
                    end: "",
                    idStart: request.data.idHour,
                    idEnd: "",
                  },
                  position: 1,
                };
                currentSheet.periods.push(newPeriod);
              }

              currentSheet.periods = reorderPeriods(currentSheet.periods);

              const { pause, totalHours } = calculateTotalHoursAndPause(
                currentSheet.periods,
                missionList.timeCut
              );
              currentSheet.pause = pause;
              currentSheet.totalHours = totalHours;

              mission = {
                ...mission,
                timeSheets: [currentSheet],
                markup: {
                  start: currentSheet.periods[0].hours.start,
                  end: currentSheet.periods[currentSheet.periods.length - 1]
                    .hours.end,
                  pause: currentSheet.pause || "",
                },
              };

              response.data.missions[j] = mission;
            }
          }
        }
      }

      resolve(response.data);
    }
  } catch (error) {
    console.log(
      "error",
      error,
      (cacheKey.startsWith("post_timeSheet/addHour") ||
        cacheKey.startsWith("post_mission/updateTempWorkerPhone")) &&
        !data.skipEnqueueRequest
    );
    if (error.response && error.response.status === 401) {
      removeToken();
      window.location.href = process.env.REACT_APP_LOGIN;
      return reject(error);
    } else if (
      (cacheKey.startsWith("post_timeSheet/addHour") ||
        cacheKey.startsWith("post_mission/updateTempWorkerPhone")) &&
      !data.skipEnqueueRequest
    ) {
      await enqueueRequest({ url, method, data });
      return reject(error);
    }
    /*if (
      error.code === "ECONNABORTED" &&
      (cacheKey.startsWith("post_timeSheet/addHour") ||
        cacheKey.startsWith("post_mission/updateTempWorkerPhone")) &&
      !data.skipEnqueueRequest
    ) {
      await enqueueRequest({ url, method, data });
    } else if (
      error.code === "ERR_CONNECTION_TIMED_OUT" &&
      (cacheKey.startsWith("post_timeSheet/addHour") ||
        cacheKey.startsWith("post_mission/updateTempWorkerPhone")) &&
      !data.skipEnqueueRequest
    ) {
      await enqueueRequest({ url, method, data });
    } else if (
      error.code === "ERR_BAD_REQUEST" &&
      (cacheKey.startsWith("post_timeSheet/addHour") ||
        cacheKey.startsWith("post_mission/updateTempWorkerPhone")) &&
      !data.skipEnqueueRequest
    ) {
      await enqueueRequest({ url, method, data });
    } else if (
      error.code === "ERR_CONNECTION_RESET" &&
      (cacheKey.startsWith("post_timeSheet/addHour") ||
        cacheKey.startsWith("post_mission/updateTempWorkerPhone")) &&
      !data.skipEnqueueRequest
    ) {
      await enqueueRequest({ url, method, data });
    } else if (
      error.code === "ERR_TIMED_OUT" &&
      (cacheKey.startsWith("post_timeSheet/addHour") ||
        cacheKey.startsWith("post_mission/updateTempWorkerPhone")) &&
      !data.skipEnqueueRequest
    ) {
      await enqueueRequest({ url, method, data });
    } else if (
      error.code === "ERR_CONNECTION_REFUSED" &&
      (cacheKey.startsWith("post_timeSheet/addHour") ||
        cacheKey.startsWith("post_mission/updateTempWorkerPhone")) &&
      !data.skipEnqueueRequest
    ) {
      await enqueueRequest({ url, method, data });
    } else if (
      error.response &&
      error.response.status === 500 &&
      (cacheKey.startsWith("post_timeSheet/addHour") ||
        cacheKey.startsWith("post_mission/updateTempWorkerPhone")) &&
      !data.skipEnqueueRequest
    ) {
      await enqueueRequest({ url, method, data });
    } else if (error.response && error.response.status === 401) {
      removeToken();
      window.location.href = process.env.REACT_APP_LOGIN;
    }*/
    reject(error);
  }
};

const connectSocketWithToken = async () => {
  try {
    const token = localStorage.getItem("token");
    const badgerId = localStorage.getItem("badgerId");
    if (token) {
      const decodedToken = jwtDecode(token);

      const newRoom = {
        userId: decodedToken.userId || "",
        badgerId: badgerId || "",
      };

      // Check if current socket is connected to the correct room
      if (
        currentSocket &&
        currentRoom.userId === newRoom.userId &&
        currentRoom.badgerId === newRoom.badgerId
      ) {
        console.log("Socket already connected to the correct room");
        return currentSocket;
      }

      // If socket exists but not connected to the correct room, disconnect it
      if (currentSocket) {
        console.log("Disconnecting current socket");
        currentSocket.disconnect();
      }

      const socket = io(process.env.REACT_APP_API_URL_SOCKET, {
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: `Bearer ${token}`,
            },
          },
        },
      });

      socket.on("connect", () => {
        socket.emit("joinRoom", newRoom);
      });

      currentSocket = socket;
      currentRoom = newRoom;
      return socket;
    }
    return null;
  } catch (error) {
    console.error("Error connecting Socket.IO:", error);
  }
};

const disconnectSocket = () => {
  if (currentSocket) {
    currentSocket.disconnect();
    currentSocket = null;
    currentRoom = {};
    console.log("Socket disconnected");
  }
};

const renewTokenIfNeededAndConnectSocket = async () => {
  try {
    await renewTokenIfNeeded();
    return await connectSocketWithToken();
  } catch (error) {
    console.error("Error renewing token and connecting Socket.IO:", error);
  }
};

const enqueueRequest = async (request) => {
  const company = localStorage.getItem("company");
  if (!company) {
    console.error("No company found in localStorage");
    return;
  }

  request.company = company;

  let isDuplicate = false;
  if (request.url === "timeSheet/addHour" && request.data.idHour) {
    const allRequests = await getAllRequests();
    isDuplicate = allRequests.some(
      (requestAll) =>
        requestAll.url === "timeSheet/addHour" &&
        requestAll.data.idHour === request.data.idHour
    );
  }
  if (
    request.url === "mission/updateTempWorkerPhone" &&
    request.data.idTempWorker &&
    request.data.phone
  ) {
    const allRequests = await getAllRequests();
    isDuplicate = allRequests.some(
      (requestAll) =>
        requestAll.url === "mission/updateTempWorkerPhone" &&
        requestAll.data.idTempWorker === request.data.idTempWorker &&
        requestAll.data.phone === request.data.phone
    );
  }

  if (!isDuplicate) {
    await addRequest(request);
  } else {
    console.error("Duplicate request - not queued");
  }
};

const sendQueuedRequests = async () => {
  const company = localStorage.getItem("company");
  if (!company) {
    console.error("No company found in localStorage");
    return;
  }

  const queue = await getAllRequests();
  const filteredQueue = queue.filter((request) => request.company === company);

  while (filteredQueue.length > 0) {
    const request = filteredQueue.shift();
    try {
      await makeRequest(
        request.url,
        request.method,
        { ...request.data, useSpecialTimeout: true, skipEnqueueRequest: true },
        async () => {
          await removeRequest(request.id);
        },
        console.error
      );
    } catch (error) {
      if (
        error.code === "ECONNABORTED" ||
        error.code === "ERR_CONNECTION_TIMED_OUT" ||
        error.code === "ERR_CONNECTION_RESET" ||
        error.code === "ERR_TIMED_OUT" ||
        (error.response && error.response.status === 500) ||
        error.message === "No internet connection - request queued" ||
        error.message ===
          "Request timed out - it has been queued for later execution" ||
        error.message === "No internet access or request timed out" ||
        error.message === "Network Error" ||
        error.message === "Failed to fetch" ||
        error.message === "Network request failed" ||
        error.message === "Connection refused" ||
        error.message === "Request aborted" ||
        error.message === "Internet Disconnected" ||
        error.message === "Unable to resolve the server's DNS address"
      ) {
        console.error("Retryable error occurred:", error.message);
        filteredQueue.unshift(request);
        break; // Exit the loop to retry later
      } else {
        console.error("Non-retryable error occurred:", error.message);
      }
    }
  }
};

const checkConnection = async () => {
  if (!navigator.onLine) {
    console.error("No internet connection");
    return false;
  }

  try {
    const timeout = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Timeout")), 3000)
    );

    const fetchRequest = fetch("https://www.google.com/", {
      mode: "no-cors",
    });

    const response = await Promise.race([fetchRequest, timeout]);

    return response.ok || response.type === "opaque";
  } catch (error) {
    console.log("No internet access or request timed out", error);
    return false;
  }
};

const checkConnectionAndSendQueuedRequests = async () => {
  if (isCheckingConnection) {
    return;
  }

  isCheckingConnection = true;

  try {
    if (await checkConnection()) {
      await sendQueuedRequests();
    } else {
      console.error("No internet connection");
    }
  } finally {
    isCheckingConnection = false;
  }
};

const removeDuplicates = async () => {
  const requests = await getAllRequests();
  const seenIds = new Set();
  const seenIdTempWorker = new Set();

  //order requests by id indexedDb from later to recent
  requests.sort((a, b) => b.id - a.id);

  for (const request of requests) {
    if (request.url === "timeSheet/addHour" && request.data.idHour) {
      if (seenIds.has(request.data.idHour.toString())) {
        await removeRequest(request.id);
      } else {
        seenIds.add(request.data.idHour.toString());
      }
    }
    if (
      request.url === "mission/updateTempWorkerPhone" &&
      request.data.idTempWorker &&
      request.data.phone
    ) {
      if (seenIdTempWorker.has(request.data.idTempWorker)) {
        await removeRequest(request.id);
      } else {
        seenIdTempWorker.add(request.data.idTempWorker);
      }
    }
  }
};

window.addEventListener("online", checkConnectionAndSendQueuedRequests);

setInterval(async () => {
  if (!navigator.onLine) return;
  await checkConnectionAndSendQueuedRequests();
}, 300000);

setInterval(async () => {
  await removeDuplicates();
}, 30000);

setInterval(async () => {
  const allLogErrors = await getAllLogErrors();
  const allLog = await getAllLogs();

  const dateNow = new Date().getTime();
  const removeOldLogs = async (logs, removeFn) => {
    for (const log of logs) {
      const date = new Date(log.date).getTime();
      const diffDays = (dateNow - date) / (1000 * 60 * 60 * 24);
      if (diffDays > 45) {
        await removeFn(log.id);
      }
    }
  };

  await Promise.all([
    removeOldLogs(allLog, removeLog),
    removeOldLogs(allLogErrors, removeLogError),
  ]);
}, 86400000);

connectSocketWithToken();

const ApiDatabase = {
  postUserLogin: (data, resolve, reject) =>
    makeRequest("auth/badger/login", "post", data, resolve, reject),
  getUserLoginComplete: (data, resolve, reject) =>
    makeRequest("auth/badger/loginComplete", "get", data, resolve, reject),
  postUserLostPassword: (data, resolve, reject) =>
    makeRequest("auth/lost-password", "post", data, resolve, reject),
  postUserLostPasswordValidation: (data, resolve, reject) =>
    makeRequest("auth/lost-password/validation", "post", data, resolve, reject),
  postUserLostPasswordChange: (data, resolve, reject) =>
    makeRequest("auth/lost-password/change", "post", data, resolve, reject),
  getEmployeeById: (data, resolve, reject) =>
    makeRequest(`bigCompany/employee`, "get", data, resolve, reject),
  putPasswordEmployee: (data, resolve, reject) =>
    makeRequest(`bigCompany/employee/password`, "put", data, resolve, reject),
  getCompanies: (data, resolve, reject) =>
    makeRequest(`bigCompany/employee/companies`, "get", null, resolve, reject),
  getBadgerNames: (data, resolve, reject) =>
    makeRequest(
      `bigCompany/employee/badgerNames?companyId=${data.companyId}`,
      "get",
      data,
      resolve,
      reject
    ),
  postCreateBadgerName: (data, resolve, reject) =>
    makeRequest(
      `bigCompany/employee/badgerName`,
      "post",
      data,
      resolve,
      reject
    ),
  postAssignBadger: (data, resolve, reject) =>
    makeRequest(
      `bigCompany/employee/assignBadger`,
      "post",
      data,
      resolve,
      reject
    ),
  postBadgerCompany: (data, resolve, reject) =>
    makeRequest(
      `bigCompany/employee/badgerCompany`,
      "post",
      data,
      resolve,
      reject
    ),
  postCreateAdminPin: (data, resolve, reject) =>
    makeRequest(
      `bigCompany/employee/addAdminPin`,
      "post",
      data,
      resolve,
      reject
    ),
  postCodePinBadger: (data, resolve, reject) =>
    makeRequest(`timeSheet/addHour`, "post", data, resolve, reject),
  postTokenMissionExist: (data, resolve, reject) =>
    makeRequest(`mission/tokenMissionExist`, "post", data, resolve, reject),
  getAdminPinCode: (data, resolve, reject) =>
    makeRequest(`bigCompany/employee/adminPin`, "get", data, resolve, reject),
  getTokenMissions: (data, resolve, reject) =>
    makeRequest(
      `mission/tokenMissions?date=${data.date}`,
      "get",
      data,
      resolve,
      reject
    ),
  getLatestVersion: (data, resolve, reject) =>
    makeRequest(
      `version/latest?type=${data.type}`,
      "get",
      data,
      resolve,
      reject
    ),
  getMissions: (data, resolve, reject) => {
    makeRequest(
      `mission/list?idCompany=${data.company}&date=${data.date}`,
      "get",
      data,
      resolve,
      reject
    );
  },
  getMissionById: (data, resolve, reject) => {
    makeRequest(
      `mission/info/${data.id}?date=${data.date}`,
      "get",
      data,
      resolve,
      reject
    );
  },
  getUserMissionById: (data, resolve, reject) => {
    makeRequest(`mission/user/${data.id}`, "get", data, resolve, reject);
  },
  updateTempWorkerPhone: (data, resolve, reject) => {
    makeRequest("mission/updateTempWorkerPhone", "post", data, resolve, reject);
  },
  updateTempWorkerToken: (data, resolve, reject) => {
    makeRequest("mission/updateTempWorkerToken", "post", data, resolve, reject);
  },
  postLogAddHour: (data, resolve, reject) =>
    makeRequest(`logAddHourBadger/add`, "post", data, resolve, reject),
  renewTokenIfNeededAndConnectSocket,
  disconnectSocket,
  checkConnection,
  makeRequest,
  enqueueRequest,
};

export default ApiDatabase;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Pencil, Synchronisation, WarningCircle } from "../assets/icons";
import Button from "../components/button";
import MissionTimeSheet from "../components/mission/MissionTimeSheet";
import useModal from "../components/modal/useModal";
import NavBarMission from "../components/navbars/NavBarMission";
import PageContainerParametersDetail from "../components/pageContainer/PageContainerParametersDetail";
import useTitle from "../hooks/TitleManager";
import UseContext from "../hooks/useContext";

const Mission = () => {
  useTitle({ title: "Mission" });
  const { toggle } = useModal();
  const {
    setModalData,
    modalDataReturn,
    setModalDataReturn,
    timeCut,
    mission,
    setMission,
    missions,
    userMission,
    setUserMission,
  } = UseContext();
  const [isWaitingMission, setIsWaitingMission] = useState(true);
  const [isWaitingUserMission, setIsWaitingUserMission] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setIsWaitingMission(true);
      setIsWaitingUserMission(true);
      if (missions.length > 0) {
        const markups = [];
        let pause = "";
        let totalHours = 0;
        let date = "";
        let idTimeSheet = "";
        let isFlexibilityDate = false;
        let user = {};
        for (let i = 0; i < missions.length; i++) {
          if (missions[i].idMission === id) {
            user = missions[i].user;
            isFlexibilityDate = missions[i].isFlexibilityDate;
            date = missions[i].date;
            for (let j = 0; j < missions[i].timeSheets.length; j++) {
              pause = missions[i].timeSheets[j].pause;
              idTimeSheet = missions[i].timeSheets[j]._id;
              totalHours = missions[i].timeSheets[j].totalHours;
              for (
                let k = 0;
                k < missions[i].timeSheets[j].periods.length;
                k++
              ) {
                markups.push(missions[i].timeSheets[j].periods[k].hours);
              }
            }
          }
        }
        setMission([
          {
            markups,
            pause,
            totalHours,
            date,
            idTimeSheet,
            isFlexibilityDate,
          },
        ]);
        setUserMission(user);
        setIsWaitingMission(false);
        setIsWaitingUserMission(false);
      }
    }
  }, [id, setMission, missions, setUserMission]);

  const editTempWorkerPhone = () => {
    toggle("edit_temp_worker_phone");
    setModalData({
      idTempWorker: userMission.idTempWorker,
      phone: userMission.phone,
    });
  };

  const editTempWorkerToken = () => {
    toggle("edit_temp_worker_token");
    setModalData({
      idTempWorker: userMission.idTempWorker,
    });
  };

  useEffect(() => {
    if (modalDataReturn?.phone) {
      let userMissionChanges = { phone: modalDataReturn.phone };
      if (modalDataReturn.tokenMission) {
        userMissionChanges["tokenMission"] = modalDataReturn.tokenMission;
      }
      setUserMission({
        ...userMission,
        ...userMissionChanges,
      });
      setModalDataReturn({});
    }
    if (modalDataReturn?.tokenMission) {
      setUserMission({
        ...userMission,
        tokenMission: modalDataReturn.tokenMission,
      });
      setModalDataReturn({});
    }
  }, [modalDataReturn, userMission, setModalDataReturn, setUserMission]);

  return (
    <PageContainerParametersDetail>
      <div className={"flex flex-1 flex-col gap-6"}>
        <NavBarMission user={userMission} isWaiting={isWaitingUserMission} />
        <div className={"flex flex-1 gap-12"}>
          <div className={"w-full max-w-[654px] flex flex-col gap-8"}>
            {isWaitingUserMission ? (
              <div className={""}></div>
            ) : (
              <div
                className={
                  "flex gap-4 px-4 py-2 border border-gray-100 rounded-lg bg-white"
                }
              >
                <div className={"flex flex-col w-full"}>
                  <div className={"flex gap-1 items-center font-bold"}>
                    <div>Mission</div>
                    <WarningCircle wh={20} color={"#374151"} />
                  </div>
                  <div className={"flex"}>{id}</div>
                </div>
                <div className={"h-full w-0.5 bg-gray-100"}></div>
                <div
                  className={"flex justify-between gap-1 w-full items-center"}
                >
                  <div className={"flex flex-col"}>
                    <div className={"flex gap-1 items-center font-bold"}>
                      <div>Code pin</div>
                      <WarningCircle wh={20} color={"#374151"} />
                    </div>
                    <div className={"flex"}>
                      {userMission.tokenMission
                        ? userMission.tokenMission
                        : "Non défini"}
                    </div>
                  </div>
                  <Button
                    color={"ICON"}
                    size={"ICON"}
                    onClick={editTempWorkerToken}
                  >
                    <Synchronisation wh={20} color={"#374151"} />
                  </Button>
                </div>
                <div className={"h-full w-0.5 bg-gray-100"}></div>
                <div
                  className={"flex justify-between gap-1 w-full items-center"}
                >
                  <div className={"flex flex-col"}>
                    <div className={"flex gap-1 items-center font-bold"}>
                      <div>Téléphone</div>
                      <WarningCircle wh={20} color={"#374151"} />
                    </div>
                    <div className={"flex"}>
                      {userMission.phone ? userMission.phone : "Non défini"}
                    </div>
                  </div>
                  <Button
                    color={"ICON"}
                    size={"ICON"}
                    onClick={editTempWorkerPhone}
                  >
                    <Pencil wh={20} color={"#374151"} />
                  </Button>
                </div>
              </div>
            )}
            {isWaitingMission ? (
              <>
                {[1, 2, 3, 4, 5].map((i, index) => (
                  <MissionTimeSheet key={index} isWaiting={isWaitingMission} />
                ))}
              </>
            ) : (
              <>
                {mission?.length ? (
                  <>
                    {mission.map((item, index) => (
                      <MissionTimeSheet
                        key={index}
                        mission={item}
                        timeCut={timeCut}
                      />
                    ))}
                  </>
                ) : (
                  <div
                    className={
                      "flex flex-col gap-4 items-center justify-center"
                    }
                  >
                    <h1 className={"text-2xl font-bold"}>
                      Mission introuvable
                    </h1>
                    <p className={"text-center"}>
                      La mission que vous cherchez est introuvable.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </PageContainerParametersDetail>
  );
};

export default Mission;

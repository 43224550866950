import { ArrowLeft, Loading } from "../../assets/icons";

const NumberBoard = ({
  code,
  setCode,
  nbElements,
  isSecret,
  isLoading,
  error,
  size,
}) => {
  const addCode = (value) => {
    //max length 4
    if (code.length === nbElements) return;
    setCode(code + value);
  };

  const removeCode = () => {
    if (code.length === 0) return;
    setCode(code.slice(0, -1));
  };

  const sizePx = {
    LG: {
      badge: "w-[450px]",
      cellTop: {
        width: "w-[89px]",
        height: "h-[120px]",
      },
      keyBoard: { height: "h-[92px]", fontSize: "text-3xl", iconSize: "32" },
    },
    MD: {
      badge: "w-[350px]",
      cellTop: {
        width: "w-[66px]",
        height: "h-[90px]",
      },
      keyBoard: { height: "h-16", fontSize: "text-2xl", iconSize: "24" },
    },
    SM: {
      badge: "w-[250px]",
      cellTop: {
        width: "w-[66px]",
        height: "h-[90px]",
      },
      keyBoard: { height: "h-16", fontSize: "text-2xl", iconSize: "24" },
    },
  };

  const elementsBoard = [
    {
      value: 1,
      onClick: () => {
        addCode("1");
      },
    },
    {
      value: 2,
      onClick: () => {
        addCode("2");
      },
    },
    {
      value: 3,
      onClick: () => {
        addCode("3");
      },
    },
    {
      value: 4,
      onClick: () => {
        addCode("4");
      },
    },
    {
      value: 5,
      onClick: () => {
        addCode("5");
      },
    },
    {
      value: 6,
      onClick: () => {
        addCode("6");
      },
    },
    {
      value: 7,
      onClick: () => {
        addCode("7");
      },
    },
    {
      value: 8,
      onClick: () => {
        addCode("8");
      },
    },
    {
      value: 9,
      onClick: () => {
        addCode("9");
      },
    },
    {
      value: "",
      onClick: () => {},
    },
    {
      value: 0,
      onClick: () => {
        addCode("0");
      },
    },
    {
      value: (
        <ArrowLeft wh={sizePx[size].keyBoard.iconSize} color={"#111827"} />
      ),
      onClick: () => {
        removeCode();
      },
    },
  ];

  return (
    <div className={`${sizePx[size].badge} select-none h-fit`}>
      <div className={"flex flex-col gap-12"}>
        <div className={"flex justify-center gap-3"}>
          {Array.from({ length: nbElements }, (_, index) => index + 1).map(
            (_, index) => {
              const codeArray = code.split("");
              return (
                <div
                  key={index}
                  className={`flex justify-center items-center touchpad-cell ${sizePx[size].cellTop.width} ${sizePx[size].cellTop.height} border ${error ? "border-red-300" : "border-grey-300"} rounded-lg p-3 ${!isSecret && "text-2xl"} ${isSecret && codeArray[index] ? "bg-gray-800 text-8xl text-white" : "bg-white"}`}
                >
                  {isSecret ? (codeArray[index] ? "·" : "") : codeArray[index]}
                </div>
              );
            }
          )}
        </div>
        <div className={"grid grid-cols-3 gap-2 relative"}>
          {elementsBoard.map((element, index) => (
            <div
              key={index}
              className={`flex justify-center items-center touchpad-button w-full ${sizePx[size].keyBoard.height} border border-grey-100 bg-gray-50 rounded-lg p-3 cursor-pointer ${sizePx[size].keyBoard.fontSize} hover:bg-gray-100 transition-all`}
              onClick={element.onClick}
            >
              {element.value}
            </div>
          ))}
          {isLoading && (
            <div
              className={
                "absolute opacity-85 w-full h-full bg-gray-50 flex justify-center items-center rounded-lg"
              }
            >
              <div className={"animate-spin"}>
                <Loading wh={40} color={"#111827"} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NumberBoard;
